<template>

    <div class="v-application">
        <img src="@/assets/images/google_analytics_logo.png" class="d-block mx-auto mt-2 mb-6">

        <p>{{ $t('component_analyticsProjectConfiguration_selectProperty_title') }}</p>

        <v-autocomplete
            :items="propertiesList"
            :label="$t('component_analyticsProjectConfiguration_selectProperty_label')"
            item-text="name"
            item-value="id"
            hide-details
            :disabled="!loadSummaryCompleted"
            :loading="!loadSummaryCompleted"
            v-model="selectedProperty"
            :return-object="true"
            color="primary"
            rounded
            dense
            outlined
            class="mb-6"
        >
            <template v-slot:item="{ attrs, item, parent, selected }">
                {{ displaySelectProperty(item) }}
            </template>
            <template v-slot:selection="{ attrs, item, parent, selected }">
                {{ displaySelectProperty(item) }}
            </template>
        </v-autocomplete>


        <div :style="{height: !fromCreateProject? '140px' : '80px'}">
            <p v-if="viewsList.length>0" >{{ $t('component_analyticsProjectConfiguration_selectView_title') }}</p>
            <v-autocomplete
                v-if="viewsList.length>0"
                :items="viewsList"
                :label="$t('component_analyticsProjectConfiguration_selectView_label')"
                item-text="name"
                item-value="id"
                hide-details
                v-model="selectedView"
                :return-object="true"
                color="primary"
                rounded
                dense
                outlined
                class="mb-6"
            />
            <v-row v-if="!fromCreateProject">
                <v-col cols="12" class="text-center mt-6" v-if="selectedProperty && selectedProperty.id">
                    <v-btn color="primary" @click="saveConfig">{{ $t('component_analyticsProjectConfiguration_btnValidProperty') }}</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>

</template>


<script>


import axios from "axios";

const axiosForGoogleAuthenticator = axios.create({
    baseURL: `${process.env.VUE_APP_YOODA_ONE_GOOGLE_AUTHENTICATOR_BASE_URI}`,
})
import analyticsApiCalls from "@/api/axios/configuration/analytics";
import projectApiCalls from "@/api/axios/configuration/project";
import AnalyticsDisplayError from "@/components/yooda-components/analytics/AnalyticsDisplayError.vue";



export default {
    components: {
        AnalyticsDisplayError
    },
    props: {
        fromCreateProject:{
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            loadSummaryCompleted:false,
            selectedProperty: null,
            selectedView: null,
            propertiesList:[],
            viewsList:[],
        }
    },

    watch:{
        loadSummaryCompleted(){
            if(this.loadSummaryCompleted){
                this.propertiesList.sort(function (a, b) {
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    if (b.name.toLowerCase() > a.name.toLowerCase()) {
                        return -1;
                    }
                    return 0;
                });

                this.propertiesList.unshift({id:0, name:'Aucun'})

                if(this.propertiesList.length > 0){
                    this.selectedProperty = this.propertiesList[0]
                }
            }
        },

        selectedProperty(){
            this.viewsList = []
            this.selectedView = null
            if(this.selectedProperty.profiles){
                this.viewsList = this.selectedProperty.profiles
                this.selectedView = this.viewsList[0]
            }
        },
    },
    methods:{
        isUaProperty(analyticsProperty){
            return analyticsProperty && analyticsProperty.split('UA').length > 1
        },


        displaySelectProperty(item){
            if(item.id === 0 ){
                return item.name
            }
            return item.name + ' - ' + (this.isUaProperty(item.id) ? item.id : 'GA4-'+item.id)
        },

        getSelectedConfig(){
            let viewConfiguration = {
                account: this.selectedProperty.account,
                property:{id:this.selectedProperty.id, name:this.selectedProperty.name}
            }
            if(this.selectedView){ viewConfiguration.view = this.selectedView }

            return { dataSourceId:this.selectedProperty.dataSourceId, viewConfiguration:viewConfiguration }
        },

        saveConfig(){
            let dataSource = this.getSelectedConfig()
            projectApiCalls.updateProject(this.$route.params.projectId,{analyticsViewConfiguration:dataSource.viewConfiguration, analyticsDataSource:dataSource.dataSourceId})
                .then((response)=>{
                    if(response){
                        this.setDataComponent( {
                            stateName:'projectInfo',
                            promise: projectApiCalls.getProjectInfo(this.$route.params.projectId),
                            dataFormatter:  this.projectData
                        })
                    }
                })
        },
        getAnalyticsSummary(){

            this.propertiesList = []
            let count = 1
            this.loadSummaryCompleted = false
            this.analyticsDataSource.data.forEach(data=>{
                if(data.category === 'google_analytics'){
                    let promise =  axiosForGoogleAuthenticator.get(`/analytics/account-summaries/${data.token}`)
                    promise.then((response)=>{
                        if(response) {
                            if(!response.data.error){
                                Object.values(response.data.accountSummaries).forEach(account=>{
                                    account.properties.forEach(property=>{
                                        if(!this.propertiesList.find(element => element.id === property.id)){
                                            this.propertiesList.push({
                                                ...property,
                                                ...{account:{id:account.id, name:account.name},dataSourceId:data.id}
                                            })
                                        }
                                    })
                                })
                                if(count >= this.analyticsDataSource.data.filter(data=>data.category==='google_analytics').length){
                                    this.loadSummaryCompleted = true
                                }
                                count++
                            }
                        }
                    })
                }

            })
        },
    },
    beforeMount() {
        if(!this.analyticsDataSource.isLoaded){

            this.setDataComponent({
                stateName:'analyticsDataSource',
                promise: analyticsApiCalls.getDataSources(this.$route.params.subscriptionId, null),
                dataFormatter: this.formatDataSource,
                asyncFormatter:true

            }).then((response)=>{
                if(response){
                    this.getAnalyticsSummary()
                }
            })
        }
        else{
            this.getAnalyticsSummary()
        }
    }

}
</script>

<style lang="scss">


</style>
