<template>

    <div class="v-application">
        <img src="@/assets/images/matomo_logo.png" class="d-block mx-auto mt-0 mb-4">


        <div class="text-center" >
            <v-row>
                <v-col :cols="3">

                </v-col>
                <v-col :cols="6">
                    <p class="mb-3">{{ $t('component_matomoAnalyticsProjectConfiguration_selectUrlLabel') }}</p>
                    <v-select
                        rounded
                        :items="matomoUrlList"
                        :label="$t('component_matomoAnalyticsProjectConfiguration_selectUrlPlaceholder')"
                        dense
                        v-model="selectedMatomoServer"
                        return-object
                        item-text="displayName"
                        item-value="id"
                        outlined
                    ></v-select>
                </v-col>
                <v-col :cols="3">

                </v-col>
            </v-row>
            <v-row class="mt-0">
                <v-col :cols="3">

                </v-col>
                <v-col :cols="6">
                    <p class="mb-1">{{ $t('component_matomoAnalyticsProjectConfiguration_siteIdLabel') }}</p>
                    <v-text-field
                        class="mb-2"
                        :placeholder="$t('component_matomoAnalyticsProjectConfiguration_siteIdPlaceholder')"
                        v-model="matomoSiteId"
                        hide-details
                        outlined
                        rounded
                        dense
                        type="number"
                        :class="`${matomoErrorId ? 'matomo-error-check': ''}`"
                        :disabled="matomoUrlList.length === 0"
                    />
                </v-col>
                <v-col :cols="3"  align-self="center" :class="!matomoErrorId ? 'pt-8' : 'pt-0 pb-0'">
                    <div v-if="matomoErrorId">
                        <v-chip color="#FFEBEE" small><span style="color: #F44336">{{ $t('component_wizardConnectedAnalytics_matomoErrorId') }}</span></v-chip>
                    </div>
                    <a href="https://one.yooda.com/fr/help/getting-started/connect-matomo" target="_blank" class="caption underline">{{ $t('component_wizardConnectedAnalytics_matomoHelpId') }}</a>
                </v-col>
            </v-row>

            <v-row class="mt-0">
                <v-col :cols="12" class="text-center">
                    <v-btn color="primary" class="d-inline-block" @click="checkMatomoConfig">{{ $t('component_matomoAnalyticsProjectConfiguration_saveBtnLabel') }}</v-btn>
                    <p class="pt-1 pl-2 green--text text--lighten-1 d-inline-block" style="position: absolute" v-if="showCheckMatomoOk"><v-icon color="green">mdi-check-circle-outline</v-icon> {{ $t('component_matomoAnalyticsProjectConfiguration_checkOkMessage') }}</p>
                </v-col>
            </v-row>


        </div>


    </div>

</template>


<script>

import axios from "axios";
const axiosForGoogleAuthenticator = axios.create({
    baseURL: `${process.env.VUE_APP_YOODA_ONE_GOOGLE_AUTHENTICATOR_BASE_URI}`,
})

import AnalyticsDisplayError from "@/components/yooda-components/analytics/AnalyticsDisplayError.vue";
import analyticsApiCalls from "@/api/axios/configuration/analytics";
import projectApiCalls from "@/api/axios/configuration/project";


export default {
    components: {
        AnalyticsDisplayError
    },
    props: {
        fromCreateProject:{
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            matomoUrlList:[],
            selectedMatomoServer:null,
            matomoSiteId:null,
            matomoErrorId:false,
            showCheckMatomoOk:false
        }
    },
    methods:{
        checkMatomoConfig(){
            this.matomoErrorId = false
            this.showCheckMatomoOk = false
            if(this.matomoSiteId){
                let promise =  axiosForGoogleAuthenticator.get(`/matomo/check-config/url/${this.selectedMatomoServer.identifier}/token/${this.selectedMatomoServer.token}/id/${this.matomoSiteId}`)
                promise.then((response)=>{
                    if(response.data.errorType){
                        if(response.data.errorType === 'BAD_ID' || response.data.errorType === 'BAD_TOKEN'){ this.matomoErrorId = true }
                    }else{
                        this.showCheckMatomoOk = true
                        if(!this.fromCreateProject){
                            let dataSource = this.getSelectedConfig()
                            projectApiCalls.updateProject(this.$route.params.projectId,{analyticsViewConfiguration:dataSource.viewConfiguration, analyticsDataSource:dataSource.dataSourceId})
                                .then((response)=>{
                                    if(response){
                                        this.setDataComponent( {
                                            stateName:'projectInfo',
                                            promise: projectApiCalls.getProjectInfo(this.$route.params.projectId),
                                            dataFormatter:  this.projectData
                                        })
                                    }
                                })
                        }

                    }
                })
            }
            else{
                this.matomoErrorId = !this.matomoSiteId
            }
        },


        getSelectedConfig(){
            return {
                dataSourceId:this.selectedMatomoServer.id,
                viewConfiguration:{
                    site: { id:parseInt(this.matomoSiteId) }
                }
            }
        },
        setMatomoServerUrlList(){
            this.matomoUrlList =  this.analyticsDataSource.data.filter(data=>{ return data.category === 'matomo_analytics' })

            if(this.matomoUrlList.length > 0){
                this.selectedMatomoServer = this.matomoUrlList[0]
            }
        }

    },
    beforeMount() {
        if(!this.analyticsDataSource.isLoaded){
            this.setDataComponent({
                stateName:'analyticsDataSource',
                promise: analyticsApiCalls.getDataSources(this.$route.params.subscriptionId, null),
                dataFormatter: this.formatDataSource,
                asyncFormatter:true

            }).then((response)=>{
                if(response){
                    this.setMatomoServerUrlList()
                }
            })
        }
        else{
            this.setMatomoServerUrlList()
        }
    }

}
</script>

<style lang="scss">

</style>
