<template>

    <div class="v-application">
        <div class="rounded-lg grey lighten-4 px-4 py-3" style="border: 1px dashed #d0d0d0 !important;" >

            <div v-if="!initialisationComplete" style="position: relative; min-height: 92px">
                <div class="v-application text-center" style="top: 50%; position: absolute; left: 50%">
                    <v-progress-circular indeterminate color="primary"/>
                </div>
            </div>



            <div v-else>
                <div style="position: relative" v-if="hasDataSource">
                    <v-tooltip left nudge-bottom="80" nudge-right="20" color="primary" v-if="withAddButton">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                fab
                                dark
                                small
                                absolute
                                top
                                right
                                v-bind="attrs"
                                v-on="on"
                                style="top: 0; right: 0"
                                @click="connectDataSource()"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <div class="py-3" style="font-size: 12px; line-height: 16px" v-html="tooltipAddDataSource"/>
                    </v-tooltip>


                    <div class="inline-block align-middle mr-6" style="max-width: 80px">
                        <img :src="logoMini" width="50" />
                    </div>

                    <div class="inline-block align-middle" style="max-width: calc(100% - 90px)">
                        <div style="position: relative" >
                            <h6 class="text-h6 mb-4">{{ titleDataSourceList }}</h6>

                            <v-chip
                                class="ma-2 chips-analytics"
                                color="white"
                                :text-color="account.tokenValid ? 'primary' : 'red'"
                                v-for="account in analyticsDataSource.data"
                                :key="account.id"
                                :close="canDeleteAccount"
                                @click:close="showDeleteConfirmation(account)"
                                :class="account.tokenValid ? '' : 'chips-error'"
                                v-if="account.category === dataSourceType"
                            >
                                <span
                                    class="rounded-circle white--text inline-block text-center font-weight-medium mr-2 py-2"
                                    style="width: 30px; height: 30px; font-size: 16px; line-height: 16px; margin-left: -11px"
                                    :class="account.tokenValid ? 'primary' : 'red'"
                                    v-if="account.identifier"
                                >
                                    {{ account.displayName.charAt(0).toUpperCase() }}
                                </span>
                                {{ account.displayName }}
                                <v-icon class="ml-2" color="red" v-if="!account.tokenValid" @click="connectDataSource(account)">mdi-refresh</v-icon>
                            </v-chip>
                        </div>
                    </div>

                </div>


                <div v-else style="max-height: 84px">
                    <img :src="logoFull" style="max-height: 55px" class="d-block mx-auto mt-2 mb-4">
                    <div class="text-center">
                        <v-btn
                            color="primary"
                            class="bt-google-connect"
                            large
                            @click="connectDataSource()"
                        >
                            <img src="../../assets/images/matomo_logo_letter.png" width="20" v-if="dataSourceType==='matomo_analytics'">
                            <v-icon left v-else>mdi-google</v-icon>
                            <strong  class="pl-2">{{ connectDataSourceLabel }}</strong>
                        </v-btn>

                    </div>
                </div>
            </div>


            <div class="text-center pt-2 mt-6" style="height: 32px">
                <v-chip color="#FFEBEE" small v-if="hasErrorDataSource">
                    <span style="color: #F44336">{{ $t('component_analyticsDisplayError_renew_authorization') }}</span>
                </v-chip>

                <analytics-display-error ref="dataSourceErrorMessage"/>

            </div>



        </div>


        <v-dialog v-model="showAddMatomoAccount">
            <v-card width="400" class="v-application">
                <v-card-title class="text-h5 font-weight-bold grey lighten-3 primary--text text-center d-block">
                    <span v-if="!accountToUpdate">{{ $t('component_analyticsMatomoAccountConfiguration_addAccountTitle') }}</span>
                    <span v-else>{{ $t('component_analyticsMatomoAccountConfiguration_updateAccountTitle') }}</span>
                </v-card-title>

                <v-card-text class="pa-4 text-body-1 text-center">
                    <p class="mt-4 mb-1">{{ $t('component_wizardConnectedAnalytics_matomoUrlLabel') }}</p>
                    <v-text-field
                        class="mb-2"
                        v-model="matomoUrlToAdd"
                        :placeholder="$t('component_wizardConnectedAnalytics_matomoUrl')"
                        dense
                        hide-details
                        @blur="cleanUrl"
                        outlined
                        rounded
                        :class="`${matomoErrorUrl ? 'matomo-error-check': ''}`"
                    />
                    <div v-if="matomoErrorUrl">
                        <v-chip color="#FFEBEE" small><span style="color: #F44336">{{ $t('component_wizardConnectedAnalytics_matomoErrorUrl') }}</span></v-chip>
                    </div>

                    <p class="mt-6 mb-1">{{ $t('component_wizardConnectedAnalytics_matomoTokenLabel') }}</p>
                    <v-text-field
                        class="mb-2"
                        v-model="matomoTokenToAdd"
                        :placeholder="$t('component_wizardConnectedAnalytics_matomoToken')"
                        dense
                        hide-details
                        outlined
                        rounded
                        :class="`${matomoErrorToken ? 'matomo-error-check': ''}`"
                    />
                    <div v-if="matomoErrorToken">
                        <v-chip color="#FFEBEE" small><span style="color: #F44336">{{ $t('component_wizardConnectedAnalytics_matomoErrorToken') }}</span></v-chip>
                    </div>
                    <a href="https://one.yooda.com/fr/help/getting-started/connect-matomo" target="_blank" class="caption underline mb-4 d-inline-block">{{ $t('component_wizardConnectedAnalytics_matomoHelpToken') }}</a>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="addMatomoAccount" v-if="!accountToUpdate">
                        {{ $t('component_analyticsMatomoAccountConfiguration_addAccountConfirmButton') }}
                    </v-btn>
                    <v-btn color="primary" @click="updateMatomoDataSource" v-else>
                        {{ $t('component_analyticsMatomoAccountConfiguration_updateAccountConfirmButton') }}
                    </v-btn>

                    <v-btn @click="showAddMatomoAccount = false">
                        {{ $t('component_analyticsMatomoAccountConfiguration_addAccountCancelButton')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="showConfirmRemoveAccount">
            <v-card width="400" class="v-application">
                <v-card-title class="text-h5 font-weight-bold grey lighten-3 error--text">
                    {{ $t('component_analyticsAccountConfiguration_confirmRemoveAssociation_title') }}
                </v-card-title>

                <v-card-text class="pa-4 text-body-1">
                    <p v-html="deleteConfirmMessage"></p>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error" @click="deleteAccountSync(dataSourceToDelete)">
                        {{ $t('component_analyticsAccountConfiguration_confirmRemoveAssociation_btnConfirm') }}
                    </v-btn>

                    <v-btn @click="showConfirmRemoveAccount = false">
                        {{ $t('component_analyticsAccountConfiguration_confirmRemoveAssociation_btnCancel')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>



import analyticsApiCalls from "@/api/axios/configuration/analytics";
import axios from "axios";
import AnalyticsDisplayError from "@/components/yooda-components/analytics/AnalyticsDisplayError.vue";

import Vue from 'vue'
const axiosForGoogleAuthenticator = axios.create({
    baseURL: `${process.env.VUE_APP_YOODA_ONE_GOOGLE_AUTHENTICATOR_BASE_URI}`,
})

export default {
    props: {
        dataSourceType:{
            type: String,
            default: 'google_analytics'
        },
        canDeleteAccount:{
            type: Boolean,
            default: false
        },
        withAddButton:{
            type: Boolean,
            default: true
        },
    },

    data() {
        return {
            dataSourceToDelete:null,
            showConfirmRemoveAccount:false,
            initialisationComplete:false,
            showAddMatomoAccount:false,
            accountToUpdate:null,
            matomoUrlToAdd:null,
            matomoTokenToAdd:null,
            matomoErrorUrl:false,
            matomoErrorToken:false,
        }
    },

    components:{
        AnalyticsDisplayError
    },
    computed:{
        hasDataSource(){
            if(this.analyticsDataSource.data.length > 0){
                return  this.analyticsDataSource.data.filter(data=>{ return data.category === this.dataSourceType }).length >0
            }
            return false
        },
        hasErrorDataSource(){
            if(this.analyticsDataSource.data.length > 0){
                return  this.analyticsDataSource.data.filter(data=>{ return (data.category === this.dataSourceType) && (!data.tokenValid) }).length >0
            }
            return false
        },
        logoFull(){
            if(this.dataSourceType === 'google_analytics'){return  require('@/assets/images/google_analytics_logo.png')}
            if(this.dataSourceType === 'matomo_analytics'){return  require('@/assets/images/matomo_logo.png')}
            if(this.dataSourceType === 'google_sheet'){return  require('@/assets/images/google_sheet_logo.png')}

            return false
        },
        logoMini(){
            if(this.dataSourceType === 'google_analytics'){return  require('@/assets/images/google_analytics_logo_mini.png')}
            if(this.dataSourceType === 'matomo_analytics'){return  require('@/assets/images/matomo_logo_mini.png')}
            if(this.dataSourceType === 'google_sheet'){return  require('@/assets/images/google_sheet_logo_mini.png')}

            return false
        },
        tooltipAddDataSource(){
            switch (this.dataSourceType){
                case 'google_analytics': return this.$t('component_analyticsAccountConfiguration_addAccount_tooltipTitle')
                case 'matomo_analytics': return this.$t('component_analyticsMatomoAccountConfiguration_addAccountTooltipTitle')
                case 'google_sheet': return this.$t('component_googleSheetAccountManagement_addAccount_tooltipTitle')
                default: return ''
            }
        },
        titleDataSourceList(){
            let title = ''
            switch (this.dataSourceType){
                case 'google_analytics':
                    title = this.withAddButton ? this.$t('component_analyticsAccountConfiguration_connectButton') : this.$t('component_analyticsAccountConfiguration_my_accounts')
                    break
                case 'matomo_analytics':
                    title = this.withAddButton ? this.$t('component_analyticsMatomoAccountConfiguration_connectButton') : this.$t('component_analyticsMatomoAccountConfiguration_my_accounts')
                    break
                case 'google_sheet':
                    title = this.withAddButton ? this.$t('component_googleSheetAccountManagement_connectButton') : this.$t('component_googleSheetAccountManagement_addAccount_title')
                    break
            }

           return title

        },
        deleteConfirmMessage(){
            switch (this.dataSourceType){
                case 'google_analytics': return this.$t('component_analyticsAccountConfiguration_confirmRemoveAccount_text')
                case 'matomo_analytics': return this.$t('component_analyticsMatomoAccountConfiguration_confirmRemoveAccount_text')
                case 'google_sheet': return this.$t('component_googleSheetAccountManagement_confirmRemoveAccount_text')
                default: return ''
            }

        },
        connectDataSourceLabel(){
            let title = ''
            switch (this.dataSourceType){
                case 'google_analytics': title = this.$t('component_analyticsAccountConfiguration_connectButton')
                    break
                case 'matomo_analytics': title = this.$t('component_analyticsMatomoAccountConfiguration_connectButton')
                    break
                case 'google_sheet': title = this.$t('component_googleSheetAccountManagement_connectButton')
                    break
            }
           return title
        }
    },

    methods:{
        cleanUrl(){
            if(this.matomoUrlToAdd){
                if(this.matomoUrlToAdd.charAt(this.matomoUrlToAdd.length -1) === '/'){
                    this.matomoUrlToAdd = this.matomoUrlToAdd.slice(0,-1)
                }
            }

        },
        connectDataSource(accountToUpdate=null){
            switch (this.dataSourceType){
                case 'google_analytics':
                    window.location = `${process.env.VUE_APP_YOODA_ONE_GOOGLE_AUTHENTICATOR_BASE_URI}/authentication?scope-analytics=true&callback-url=${`${window.location.href}?restoreProject=${this.fromCreateProject ? 'true' : 'false'}`}`
                    break
                case 'matomo_analytics':
                    this.showAddMatomoAccount = true
                    this.accountToUpdate = accountToUpdate
                    if(accountToUpdate){
                        this.matomoTokenToAdd = accountToUpdate.token
                        this.matomoUrlToAdd = accountToUpdate.identifier
                    }

                    break
                case 'google_sheet':
                    window.location = `${process.env.VUE_APP_YOODA_ONE_GOOGLE_AUTHENTICATOR_BASE_URI}/authentication?scope-drive=true&callback-url=${`${window.location.href}?restoreProject=${this.fromCreateProject ? 'true' : 'false'}`}`
                    break
            }
        },
        async checkMatomoDataSource(){
            this.matomoErrorUrl = false
            this.matomoErrorToken = false
            if(this.matomoUrlToAdd && this.matomoTokenToAdd){
                let promise =  axiosForGoogleAuthenticator.get(`/matomo/check-config/url/${this.matomoUrlToAdd}/token/${this.matomoTokenToAdd}/id/null`)
                return promise.then((response)=>{
                    if(response.data.errorType){
                        if(response.data.errorType === 'BAD_URL'){ this.matomoErrorUrl = true }
                        if(response.data.errorType === 'BAD_TOKEN'){ this.matomoErrorToken = true }
                    }
                })
            }
            else{
                this.matomoErrorUrl = !this.matomoUrlToAdd
                this.matomoErrorToken = !this.matomoTokenToAdd
                return false
            }
        },
        async addMatomoAccount(){
            await this.checkMatomoDataSource()

            if(!this.matomoErrorUrl && !this.matomoErrorToken){
                analyticsApiCalls.addDataSource(this.$route.params.subscriptionId,{
                    category: 'matomo_analytics',
                    identifier: this.matomoUrlToAdd,
                    token: this.matomoTokenToAdd
                }).then((response)=>{
                    if(response){
                        this.matomoUrlToAdd = null
                        this.matomoTokenToAdd = null
                        this.showAddMatomoAccount = false
                        this.initComponentData(true)
                    }
                }).catch(()=>{
                    this.matomoUrlToAdd = null
                    this.matomoTokenToAdd = null
                    this.showAddMatomoAccount = false
                })
            }


        },
        async updateMatomoDataSource(){
            await this.checkMatomoDataSource()

            if(!this.matomoErrorUrl && !this.matomoErrorToken){
                analyticsApiCalls.updateAnalyticsDataSource(this.accountToUpdate.id,{
                    category: 'matomo_analytics',
                    identifier: this.matomoUrlToAdd,
                    token: this.matomoTokenToAdd
                }).then((response)=>{
                    if(response){
                        this.matomoUrlToAdd = null
                        this.matomoTokenToAdd = null
                        this.showAddMatomoAccount = false
                        this.initComponentData(true)
                    }
                })
            }

        },
        checkToken(refreshToken){
            let promise =  axiosForGoogleAuthenticator.get(`/analytics/account-summaries/${refreshToken}`)
            if(this.dataSourceType === 'google_sheet'){
                promise =  axiosForGoogleAuthenticator.get(`/drive/summaries/${refreshToken}`)
            }

            promise.then((response)=>{
                if(!response || response.data.error){
                    if(this.dataSourceType === 'google_sheet'){
                        this.$refs.dataSourceErrorMessage.displayAuthorizationError('sheet')
                    }else{
                        this.$refs.dataSourceErrorMessage.displayAuthorizationError()
                    }

                    this.revokeAccountAccess(refreshToken)
                    this.$router.push(this.$route.path)
                }
                else{
                    analyticsApiCalls.addDataSource(this.$route.params.subscriptionId,{category:this.dataSourceType,identifier:response.data.user.email,token:refreshToken})
                        .then((addedResponse) => {
                            if(addedResponse){
                                this.initComponentData(true)
                            }
                        })
                        .catch(()=>{
                            this.updateDataSourceByEmail(response.data.user.email, refreshToken)
                        }).finally(()=>{
                        this.$router.push(this.$route.path)
                    })
                }
            })
        },
        updateDataSourceByEmail(accountEmail, token){
            analyticsApiCalls.getDataSources(this.$route.params.subscriptionId, this.dataSourceType).then((response)=>{
                if(response){
                    response.content.forEach((dataSource)=>{
                        if(dataSource.identifier === accountEmail){
                            analyticsApiCalls.updateAnalyticsDataSource(dataSource.id,{token:token, tokenValid:true}).then(()=>{
                                if(this.$refs.dataSourceErrorMessage){
                                    this.$refs.dataSourceErrorMessage.hideError()
                                }
                                this.initComponentData(true)
                            })
                        }
                    })
                }
            })
        },
        showDeleteConfirmation(dataSource){
            this.dataSourceToDelete = dataSource
            this.showConfirmRemoveAccount = true
        },
        deleteAccountSync(){
            analyticsApiCalls.deleteDataSource(this.dataSourceToDelete.id)
                .then(() => {
                    this.showConfirmRemoveAccount = false
                    this.initComponentData()
                })
        },

        revokeAccountAccess(token){
            let promise =  axiosForGoogleAuthenticator.get(`/revoke-access/${token}`)
            promise.then((response)=>{
                if(!response) {
                    this.$vs.notify({
                        time:5000,
                        title:'Erreur ',
                        text:'Problème lors de la révocation de l\'access à votre compte Google',
                        position:'top-right',
                        color:'danger'
                    })
                }
                else{
                    this.initComponentData()
                }
            }).catch((error)=>{
                Vue.error('get analytics summary',error)
            })
        },



        initComponentData(emitCreateEvent=false){
            this.initialisationComplete = false
            this.setDataComponent({
                stateName:'analyticsDataSource',
                promise: analyticsApiCalls.getDataSources(this.$route.params.subscriptionId, null),
                dataFormatter: this.formatDataSource,
                asyncFormatter:true

            }).then((response)=>{
                if(response){
                    this.initialisationComplete = true
                    if(emitCreateEvent){
                        this.$emit('addExternalDataSource')
                    }
                }
            })

        }
    },

    mounted() {

        if((this.$route.query.refreshTokenDrive && this.dataSourceType === 'google_sheet') || (this.$route.query.refreshTokenAnalytics && this.dataSourceType === 'google_analytics')){
            this.checkToken(this.$route.query.refreshTokenAnalytics ? this.$route.query.refreshTokenAnalytics : this.$route.query.refreshTokenDrive)
        }else{
            if(!this.analyticsDataSource.isLoaded){
                this.initComponentData()
            }
            else{
                this.initialisationComplete = true
            }
        }


    },
}
</script>

<style lang="scss">
.chips-analytics{
    background-color: #fff !important;
}
.chips-error{
    border: 1px solid red !important;
    padding: 0 10px !important;
}

</style>

