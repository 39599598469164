<template>
    <v-row >
        <v-col :cols="6">
            <component-container
                :card-title="$t('component_analyticsProjectManagement_title')"
                :card-subtitle="$t('component_analyticsProjectManagement_subtitle')"

                :height="740"

                :information-tooltip="{
                    title: $t('component_analyticsProjectManagement_informationTooltip_title'),
                    text: $t('component_analyticsProjectManagement_informationTooltip_text')
                }"
                :component-data="{response:{isLoaded:isInitialised}, hasData:true}"
            >
                <template v-slot:content>
                    <analytics-project-configuration
                        :can-delete-account="false"
                        :from-create-project="false"
                        v-if="!project.data.analyticsDataSource"
                    />
                    <analytics-project-summary v-else/>

                </template>
            </component-container>
        </v-col>
    </v-row>

</template>


<script>
import apiCalls from "@/api/axios/configuration/project";
import analyticsApiCalls from "@/api/axios/configuration/analytics";

import AnalyticsProjectConfiguration from "@/components/yooda-components/analytics/AnalyticsProjectConfiguration.vue";
import AnalyticsProjectSummary from "@/components/yooda-components/analytics/AnalyticsProjectSummary.vue";

export default {
    components: {

        AnalyticsProjectSummary,
        AnalyticsProjectConfiguration
    },
    data(){
        return {
            isInitialised:false,
        }
    },
    computed:{


    },
    methods:{
        initComponentData(){

            this.setDataComponent({
                stateName:'analyticsDataSource',
                promise: analyticsApiCalls.getDataSources(this.$route.params.subscriptionId, null),
                dataFormatter: this.formatDataSource,
                asyncFormatter:true

            }).then(()=>{
                this.setDataComponent({
                    stateName:'projectInfo',
                    promise: apiCalls.getProjectInfo(this.$route.params.projectId, true),
                    dataFormatter: this.projectData
                }).then(()=>{
                    this.isInitialised=true
                })
            })

        }
    },
    beforeMount() {
        this.initComponentData()
    },
}

</script>
