<template>

    <div class="v-application text-center pt-10">
        <img src="@/assets/images/matomo_logo.png" class="d-block mx-auto mt-0 mb-4" v-if="dataSourceCategory === 'matomo_analytics'">
        <img src="@/assets/images/google_analytics_logo.png" class="d-block mx-auto mt-0 mb-4" v-if="dataSourceCategory === 'google_analytics'">


        <div class="rounded-lg grey lighten-4 mt-12 px-4 py-3" style="border: 1px dashed #d0d0d0 !important;" >

            <ul class="d-inline-block pa-0 text-left" v-if="dataSourceCategory === 'google_analytics'">
                <li  class="my-2">
                    <strong class="d-inline-block text-right mr-2" style="width: 80px">
                        {{ $t('component_analyticsProjectConfiguration_displayCurrentConfig_email') }} :
                    </strong>
                    <span v-if="project.data.analyticsDataSource">{{ project.data.analyticsDataSource.identifier }}</span>
                </li>
                <li  v-if="project.data.analyticsViewConfiguration.account" class="my-2">
                    <strong class="d-inline-block text-right mr-2" style="width: 80px">
                        {{ $t('component_analyticsProjectConfiguration_displayCurrentConfig_account') }} :
                    </strong>
                    <span>{{project.data.analyticsViewConfiguration.account.name}}</span>
                </li>
                <li class="my-2">
                    <strong class="d-inline-block text-right mr-2" style="width: 80px">
                        {{ $t('component_analyticsProjectConfiguration_displayCurrentConfig_property') }} :
                    </strong>
                    <span>{{project.data.analyticsViewConfiguration.property.name}}</span>
                </li>
                <li class="my-2" v-if="isUaProperty(project.data.analyticsViewConfiguration.property.id)">
                    <strong class="d-inline-block text-right mr-2" style="width: 80px">
                        {{ $t('component_analyticsProjectConfiguration_displayCurrentConfig_view') }} :
                    </strong>
                    <span>{{project.data.analyticsViewConfiguration.view.name}}</span>
                </li>
            </ul>

            <ul class="d-inline-block pa-0 text-left" v-if="dataSourceCategory === 'matomo_analytics'">
                <li  class="my-2">
                    <strong class="d-inline-block text-right mr-2" style="width: 135px">
                        {{ $t('component_analyticsProjectConfiguration_displayCurrentConfig_server') }} :
                    </strong>
                    <span>{{ project.data.analyticsDataSource.identifier }}</span>
                </li>
                <li  class="my-2">
                    <strong class="d-inline-block text-right mr-2" style="width: 135px">
                        {{ $t('component_analyticsProjectConfiguration_displayCurrentConfig_idSite') }} :
                    </strong>
                    <span>{{project.data.analyticsViewConfiguration.site.id}}</span>
                </li>
            </ul>
        </div>

        <p class="mt-8 success--text text-subtitle-1">
            <v-icon color="success" class="mr-4">mdi-checkbox-marked-circle-outline</v-icon>
            {{ $t('component_analyticsProjectManagement_AccountSyncText') }}
        </p>

        <v-btn
            class="mt-12"
            outlined
            @click="showConfirmRemoveAssociation = true"
            color="error"
        >
            {{$t('component_analyticsProjectManagement_DeleteAccountConfiguration')}}
        </v-btn>


        <v-dialog v-model="showConfirmRemoveAssociation">
            <v-card width="400" class="v-application">
                <v-card-title class="text-h5 font-weight-bold grey lighten-3 error--text">
                    {{ $t('component_analyticsProjectConfiguration_confirmRemoveAssociation_title') }}
                </v-card-title>

                <v-card-text class="pa-4 text-body-1">
                    <p v-html="$t('component_analyticsProjectConfiguration_confirmRemoveAssociation_text')" v-if="dataSourceCategory === 'google_analytics'"/>
                    <p v-html="$t('component_analyticsMatomoProjectConfiguration_confirmRemoveAssociation_text')" v-if="dataSourceCategory === 'matomo_analytics'"/>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error" @click="deleteProjectAnalyticsSync">
                        {{ $t('component_analyticsProjectConfiguration_confirmRemoveAssociation_btnConfirm') }}
                    </v-btn>

                    <v-btn @click="showConfirmRemoveAssociation = false">
                        {{ $t('component_analyticsProjectConfiguration_confirmRemoveAssociation_btnCancel')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


    </div>

</template>


<script>



import projectApiCalls from "@/api/axios/configuration/project";

export default {


    data() {
        return {
            showConfirmRemoveAssociation:false
        }
    },

    computed:{
        dataSourceCategory(){
            return this.project.data.analyticsDataSource.category
        }

    },
    methods:{
        isUaProperty(analyticsProperty){
            return analyticsProperty && analyticsProperty.split('UA').length > 1
        },

        deleteProjectAnalyticsSync(){
            let promise = projectApiCalls.updateProject(this.$route.params.projectId,{analyticsDataSource:null})
            promise.then((response)=>{
                if(response){
                    this.setDataComponent( {
                        stateName:'projectInfo',
                        promise: projectApiCalls.getProjectInfo(this.$route.params.projectId),
                        dataFormatter:  this.projectData
                    })
                    this.showConfirmRemoveAssociation = false
                }
            })
        },
    },

}
</script>

<style lang="scss">


</style>
