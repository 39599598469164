<template>


    <div class="v-application" v-if="!project.data.analyticsDataSource">

        <v-row :class="!dataSourceTypeSelected ? 'mt-12' : 'mt-2'">
            <v-col :cols="12" v-if="!dataSourceTypeSelected">
                <p class="mt-1 mb-6 font-weight-medium text-center">{{ $t('component_projectCreationWizard_stepAnalytics_selectDataSourceTypeTitle') }}</p>
            </v-col>
            <v-col :cols="12" class="text-center">
                <v-btn
                    color="primary"
                    :outlined="dataSourceTypeSelected!=='google_analytics'"
                    :height="!dataSourceTypeSelected ? '100px' : '40px'"
                    class="rounded-lg d-inline-block"
                    @click="dataSourceTypeSelected = 'google_analytics'"
                >
                    <img :src="getGoogleLogo" alt="Google Analytics" :width="!dataSourceTypeSelected ? 128 : 64">
                </v-btn>

                <p class="font-weight-bold d-inline-block" :class="!dataSourceTypeSelected ? 'text-h5 mx-8' : 'text-h6 mx-4'">{{ $t('component_projectCreationWizard_stepAnalytics_selectDataSourceChooseOr') }}</p>

                <v-btn
                    color="primary"
                    :outlined="dataSourceTypeSelected!=='matomo_analytics'"
                    :height="!dataSourceTypeSelected ? '100px' : '40px'"
                    class="rounded-lg d-inline-block"
                    @click="dataSourceTypeSelected = 'matomo_analytics'"
                >
                    <img :src="getMatomoLogo" alt="Matomo" :width="!dataSourceTypeSelected ? 128 : 64">
                </v-btn>

            </v-col>
            <v-col :cols="12" class="text-left" v-if="!dataSourceTypeSelected">
                <p class="mt-16 mb-6">{{ $t('component_projectCreationWizard_stepAnalytics_selectDataSourceAdviceTitle') }}</p>
                <ul style="list-style: disc; padding-left: 16px">
                    <li>{{ $t('component_projectCreationWizard_stepAnalytics_selectDataSourceAdvice1') }}</li>
                    <li class="my-2">{{ $t('component_projectCreationWizard_stepAnalytics_selectDataSourceAdvice2') }}</li>
                    <li>{{ $t('component_projectCreationWizard_stepAnalytics_selectDataSourceAdvice3') }}</li>
                </ul>
            </v-col>
        </v-row>



        <v-row v-if="dataSourceTypeSelected === 'google_analytics'" style="height: 560px">
            <v-col :cols="12">
                <google-analytics-project-configuration
                    :from-create-project="fromCreateProject"
                    class="mt-0"
                    ref="googleAnalyticsConfigurationContainer"
                    v-if="hasGoogleDataSource"
                />
            </v-col>


            <v-col :cols="12" >
                <data-source-configuration
                    class="text-left mt-3"
                    :can-delete-account="canDeleteAccount"
                    @addExternalDataSource="refreshGoogleDataSource"
                    data-source-type="google_analytics"
                    v-if="!project.data.analyticsViewConfiguration"
                />
            </v-col>

        </v-row>


        <v-row v-if="dataSourceTypeSelected === 'matomo_analytics'" style="height: 560px">
            <v-col :cols="12" >
                <matomo-analytics-project-configuration
                    :from-create-project="fromCreateProject"
                    ref="matomoAnalyticsConfigurationContainer"
                    v-if="hasMatomoDataSource"
                />
            </v-col>
            <v-col :cols="12" >
                <data-source-configuration
                    class="text-left"
                    :can-delete-account="canDeleteAccount"
                    @addExternalDataSource="refreshMatomoDataSource"
                    data-source-type="matomo_analytics"
                    v-if="!project.data.analyticsViewConfiguration"
                />
            </v-col>
        </v-row>


    </div>


</template>


<script>



import MatomoAnalyticsProjectConfiguration from "@/components/yooda-components/analytics/MatomoAnalyticsProjectConfiguration.vue";
import DataSourceConfiguration from "@/components/yooda-components/DataSourceConfiguration.vue";
import GoogleAnalyticsProjectConfiguration from "@/components/yooda-components/analytics/GoogleAnalyticsProjectConfiguration.vue";

export default {
    components: {
        GoogleAnalyticsProjectConfiguration,
        DataSourceConfiguration,
        MatomoAnalyticsProjectConfiguration
    },
    props: {
         canDeleteAccount:{
            type: Boolean,
            default: false
        },
        fromCreateProject:{
            type: Boolean,
            default: true
        },
    },

    data() {
        return {
            dataSourceTypeSelected:null,
        }
    },

    computed:{

        getGoogleLogo(){
            if(this.dataSourceTypeSelected === 'google_analytics'){return  require('@/assets/images/google_analytics_logo_white.png')}
            return require('@/assets/images/google_analytics_logo.png')
        },
        getMatomoLogo(){
            if(this.dataSourceTypeSelected === 'matomo_analytics'){return  require('@/assets/images/matomo_logo_white.png')}
            return require('@/assets/images/matomo_logo.png')
        },
        hasGoogleDataSource(){
            if(this.analyticsDataSource.data.length > 0){
                return  this.analyticsDataSource.data.filter(data=>{ return data.category === 'google_analytics' }).length >0
            }
            return false
        },
        hasMatomoDataSource(){
            if(this.analyticsDataSource.data.length > 0){
                return  this.analyticsDataSource.data.filter(data=>{ return data.category === 'matomo_analytics' }).length >0
            }
            return false
        }

    },
    methods:{
        refreshMatomoDataSource(){
            this.$refs.matomoAnalyticsConfigurationContainer.setMatomoServerUrlList()
        },
        refreshGoogleDataSource(){
            this.$refs.googleAnalyticsConfigurationContainer.getAnalyticsSummary()
        },
        getPropertyConfig(){
            if(this.dataSourceTypeSelected === 'google_analytics'){
                if(this.$refs.googleAnalyticsConfigurationContainer){
                    return this.$refs.googleAnalyticsConfigurationContainer.getSelectedConfig()
                }
                else{
                    return false
                }
            }else{
                if(this.$refs.matomoAnalyticsConfigurationContainer){
                    return this.$refs.matomoAnalyticsConfigurationContainer.getSelectedConfig()
                }
                else{
                    return false
                }
            }
        },
    },
    mounted() {

        if(this.$route.query.refreshTokenAnalytics){
            this.dataSourceTypeSelected = 'google_analytics'
        }

    },

}
</script>

<style lang="scss">


</style>
