

import axios from '@/api/axios.js'

export default {

    //*****************************\\
    //*********** CREATE ***********\\
    /**
     * @param subscriptionId integer
     * @param param
     * @returns Promise
     */
    addDataSource (subscriptionId, param) {
        return axios.post(`/configuration/subscription-metas/${subscriptionId}/data-sources`, param)
    },





    //*****************************\\
    //*********** READ ***********\\

    /**
     * @param subscriptionId integer
     * @param category string|null
     * @returns Promise
     */
    getDataSources (subscriptionId, category='google_analytics') {
        return axios.get(`/configuration/subscription-metas/${subscriptionId}/data-sources`,{params:{category:category}})
    },




    //*****************************\\
    //*********** UPDATE ***********\\

    /**
     * @param dataSourceId integer
     * @param param
     * @returns Promise
     */
    updateAnalyticsDataSource(dataSourceId, param) {
        return axios.patch(`/configuration/data-sources/${dataSourceId}`, param)
    },



    //*****************************\\
    //*********** DELETE ***********\\

    /**
     * @param tokenToDelete
     * @returns Promise
     */
    deleteDataSource (tokenToDelete) {
        return axios.delete(`/configuration/data-sources/${tokenToDelete}`)
    },



};
