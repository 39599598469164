<template>

    <div class="v-application pt-2" style="height: 32px">
        <p class="py-1 px-4 text-body-2 rounded-xl inline-block red lighten-5 red--text" v-if="alreadyAddedError">
            {{ $t('component_analyticsDisplayError_addAccount_error_alreadyExist') }}
        </p>

        <div class="py-1 pl-4 pr-8 text-body-2 inline-block rounded-xl red lighten-5 red--text" style="position: relative" v-if="authorizationError ">
            {{ $t(messageKeyError) }}

            <v-menu
                top
                nudge-right="70"
                class="information-tooltip"
                content-class="v-menu__content-tooltip"
                :close-on-content-click="false"
                style="width: 100%"
                :max-width="400"
                z-index="100000"
                transition="slide-x-transition"
                origin="bottom left"
            >
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                       <v-icon small left color="red " style="position: absolute; top:5px; right: 0">mdi-help-circle-outline</v-icon>
                    </span>
                </template>
                <div style="width: 300px; height: 600px" v-if="errorType === 'analytics'">
                    <h6>Autorisation sur l'API Google Analytics</h6>
                    <p>Pour autoriser YOODA One à importer vos statistiques Google Analytics, vous devez cocher la case sur la fenêtre d'autorisation comme dans l'exemple ci-dessous :</p>
                    <img class="mt-6" src="@/assets/images/google_analytics_help_connexion.png" style="width: 300px">
                </div>
                <div style="width: 300px; height: 600px" v-if="errorType === 'sheet'">
                    <h6>Autorisation sur l'API Google Drive</h6>
                    <p>Pour autoriser YOODA One à exporter vos données vers Google Drive, vous devez cocher la case sur la fenêtre d'autorisation comme dans l'exemple ci-dessous :</p>
                    <img class="mt-6" src="@/assets/images/google_drive_help_connexion.png" style="width: 300px">
                </div>
            </v-menu>
        </div>
    </div>

</template>


<script>



export default {


    data() {
        return {
            alreadyAddedError:false,
            authorizationError:false,
            errorType:'analytics',
            messageKeyError:''
        }
    },

    methods:{
        displayAlreadyAddedError(){
            this.alreadyAddedError = true
        },
        displayAuthorizationError(errorType = 'analytics', messageKey = 'component_analyticsDisplayError_addAccount_error_authorization'){
            this.errorType = errorType
            this.authorizationError = true
            this.messageKeyError = messageKey
        },
        hideError(){
            this.authorizationError = false
            this.alreadyAddedError = false
        }

    },

}
</script>

<style lang="scss">


</style>
